export function getOptionsFromMeta(prefix = 'ha') {
    let collectorUrl;
    const baseContext = {};
    const metaTags = document.head.querySelectorAll(`meta[name^="${prefix}-"]`);
    for (const meta of Array.from(metaTags)) {
        const { name: originalName, content } = meta;
        const name = originalName.replace(`${prefix}-`, '').replace(/-/g, '_');
        if (name === 'url') {
            collectorUrl = content;
        }
        else {
            baseContext[name] = content;
        }
    }
    if (!collectorUrl) {
        throw new Error(`AnalyticsClient ${prefix}-url meta tag not found`);
    }
    return {
        collectorUrl,
        ...(Object.keys(baseContext).length > 0 ? { baseContext } : {})
    };
}
