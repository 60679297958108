const MARKETING_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'scid'];
export function getMarketingContext() {
    const marketingContext = {};
    try {
        const urlParams = new URLSearchParams(window.location.search);
        for (const [key, value] of urlParams) {
            const normalizedKey = key.toLowerCase();
            if (MARKETING_PARAMS.includes(normalizedKey)) {
                marketingContext[normalizedKey] = value;
            }
        }
        return marketingContext;
    }
    catch (e) {
        return {};
    }
}
