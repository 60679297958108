function getReferrer() {
    let referrer;
    try {
        referrer = window.top.document.referrer;
    }
    catch (error) {
        if (window.parent) {
            try {
                referrer = window.parent.document.referrer;
            }
            catch (_) {
            }
        }
    }
    if (referrer === '') {
        referrer = document.referrer;
    }
    return referrer;
}
function getScreenResolution() {
    try {
        return `${screen.width}x${screen.height}`;
    }
    catch (error) {
        return 'unknown';
    }
}
function getBrowserResolution() {
    let height = 0;
    let width = 0;
    try {
        if (typeof window.innerWidth === 'number') {
            width = window.innerWidth;
            height = window.innerHeight;
        }
        else if (document.documentElement != null && document.documentElement.clientWidth != null) {
            width = document.documentElement.clientWidth;
            height = document.documentElement.clientHeight;
        }
        else if (document.body != null && document.body.clientWidth != null) {
            width = document.body.clientWidth;
            height = document.body.clientHeight;
        }
        return `${width}x${height}`;
    }
    catch (error) {
        return 'unknown';
    }
}
function getBrowserLanguages() {
    if (navigator.languages) {
        return navigator.languages.join(',');
    }
    else {
        return navigator.language || '';
    }
}
export function getRequestContext() {
    return {
        referrer: getReferrer(),
        user_agent: navigator.userAgent,
        screen_resolution: getScreenResolution(),
        browser_resolution: getBrowserResolution(),
        browser_languages: getBrowserLanguages(),
        pixel_ratio: window.devicePixelRatio,
        timestamp: Date.now(),
        tz_seconds: new Date().getTimezoneOffset() * -60
    };
}
